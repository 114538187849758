<template>
  <Layout>
    <template slot="body">
      <section class="box">
        <section class="why">
          <h4 class="h4">为什么选择誉照</h4>
          <div class="reason">
            <ul class="list flex">
              <li class="item" v-for="item in 3" :key="item">
                <el-image></el-image>
                <h5>智慧链接，美好生活</h5>
                <el-divider></el-divider>
                <p>
                  智慧链接，美好生活。与数字内容大
                  核心业务，积极拥抱产业互联网，努
                  力为各行业数字化助手你通过链接提 高每一个人的生活品质。
                </p>
              </li>
            </ul>
          </div>
        </section>

        <section class="search">
          <h4 class="h4">探索你的未来</h4>
          <ul class="s-list flex">
            <li class="s-item flex bb" v-for="item in 6" :key="item">
              <h5 class="flex">技术类 <i></i></h5>
              <p>查看岗位 <i class="el-icon-arrow-right"></i></p>
            </li>
          </ul>
          <div class="btm">
            <el-button>查看更多职位</el-button>
          </div>
        </section>

        <section class="welfare flex">
          <div class="welfare-item welfare-item-big bb">
            <h4 class="h4">员工大会</h4>
            <div class="modal bb">
              <p>
                以丰富多彩的活动，深入人心的文化品牌，将文化的信念不断传承。
              </p>
            </div>
          </div>
          <div class="welfare-item bb"></div>
          <div class="welfare-item bb"></div>
          <div class="welfare-item bb"></div>
          <div class="welfare-item bb"></div>
        </section>

        <div class="btm">
          <el-button>了解所有的誉照文化福利</el-button>
        </div>

        <section class="j-swiper">
          <h4 class="h4">员工心声</h4>
          <div class="swiper">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide flex"
                v-for="item in swiper"
                :key="item.id"
              >
                <el-image :src="item.src" fit="cover" />
                <div class="info flex">
                  <p class="title">
                    在誉照成长为一个领导者，我与我的团队让 PUPIPI成为
                  </p>
                  <div class="avator-box flex">
                    <el-image></el-image>
                    <div class="avator-info flex">
                      <p class="subtitle">GGSDGSDF</p>
                      <span>职位</span>
                      <span>职位</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="swiper-button-prev"></div> -->
            <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
            <!-- <div class="swiper-button-next"></div> -->
            <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
          </div>
          <!-- swiper轮播图圆点 -->
          <div class="custom-swiper-button custom-swiper-button-prev">
            <!-- <el-image :src="swiperButton" fit="fill"></el-image> -->
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="custom-swiper-button custom-swiper-button-next">
            <!-- <el-image :src="swiperButton" fit="fill"></el-image> -->
            <i class="el-icon-arrow-right"></i>
          </div>
        </section>

        <section class="j-story">
          <h4 class="h4">员工故事</h4>
          <div class="story-box flex">
            <div class="story-item" v-for="item in 2" :key="item">
              <div class="story-bg">
                <el-image></el-image>
              </div>
              <div class="story-text-wrapper bb flex">
                <span class="name">sfsf</span>
                <p>如果提前了了解要面对的人生是否有勇 气前来？</p>
                <p class="more">了解更多 <i></i></p>
                <el-divider></el-divider>
              </div>
            </div>
          </div>
        </section>
      </section>
    </template>
  </Layout>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";

@Component({
  components: {
    Layout,
  },
})
export default class Join extends Vue {
  data() {
    return {
      logo: require("@/assets/power/img/logo-b.png"),
      swiperButton: require("@/assets/home/img/icon_arrow_r.png"),
      swiper: [
        {
          id: 1,
          src: require("@/assets/home/swiper/s1.jpg"),
          slogan: "让零碳 <br />走进你的生活",
          title:
            "利用光伏技术和智慧能源管理云平台帮助你了解电怎么来？<br />利用智慧能源服务体系帮助你追踪电去了哪儿？",
        },
        {
          id: 2,
          src: require("@/assets/home/swiper/s2.jpg"),
          slogan: "源于科技，归于自然<br />零碳未来，“誉”你同行",
          title:
            "数字化清洁能源投资开发和管理平台<br />以度电成本为引领以平价上网为目标<br />通过“YuOS”智慧云驱动全生命周期降本增效",
        },
        {
          id: 3,
          src: require("@/assets/home/swiper/s3.jpg"),
          slogan: "充电，<br />让生活更惬意",
          title:
            "全场景化的加电服务<br />誉照充电网络持续布局中，满足各种出行补能需求<br/>充电桩适配所有国标车型。安全！高效！",
        },
      ],
    };
  }
  mounted() {
    this.$nextTick(() => {
      // 一旦完成界面更新, 立即执行回调
      new Swiper(".swiper", {
        // autoplay: {
        //   delay: 5000,
        // },
        // slidesPerView: 5,
        autoplay: false,
        navigation: {
          nextEl: ".custom-swiper-button-next",
          prevEl: ".custom-swiper-button-prev",
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    });
  }
}
</script>

<style lang="less" scoped>
.box {
  background: #f7f7f7;
  overflow: hidden;
  .why,
  .search,
  .j-swiper,
  .j-story {
    width: 100%;
    max-width: 1200px;
    margin: 80px auto 0;
    .h4 {
      text-align: left;
      position: relative;
      padding-left: 28px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 7px;
        height: 36px;
        background: #ef8a0f;
      }
    }
    .reason {
      width: 100%;
      margin-top: 70px;
      .list {
        justify-content: space-between;
        .item {
          width: 21%;
          color: #000;
          text-align: left;
          .el-image {
            width: 53px;
            height: 53px;
            border-radius: 50%;
            margin-bottom: 39px;
          }
          h5 {
            font-size: 24px;
            margin: 0;
          }
          .el-divider {
            margin: 20px 0;
          }
          p {
            font-size: 16px;
            margin: 0;
          }
        }
      }
    }
  }

  .search {
    margin-top: 80px;
    .h4 {
      text-align: left;
    }
    .s-list {
      width: 100%;
      flex-wrap: wrap;
      margin-top: 57px;
      .s-item {
        width: 48.25%;
        height: 130px;
        background: #fff;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        box-shadow: 0px 2px 1px 0px rgba(7, 8, 20, 0.18);
        margin-right: 3.5%;
        margin-bottom: 54px;
        padding: 13px 26px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        h5 {
          font-size: 24px;
          margin: 0;
        }
        p {
          font-size: 18px;
          color: #528ae6;
          margin: 0;
        }
      }
    }
  }
  .btm {
    width: 100%;
    text-align: center;
    .el-button {
      width: 334px;
      height: 68px;
      position: relative;
      bottom: 0;
      border-radius: 0;
      font-size: 24px;
      color: #333;
      border: 0;
      background: #fff;
      box-shadow: 0px 2px 1px 0px rgba(7, 8, 20, 0.18);
    }
  }
  .welfare {
    width: 100%;
    flex-wrap: wrap;
    margin-top: 80px;
    margin-bottom: 55px;
    .welfare-item {
      position: relative;
      width: 33.33%;
      height: 270px;
      background: url("~@/assets/home/img/lt.jpg") no-repeat;
      background-size: cover;
      text-align: left;
      color: #fff;
      padding: 47px 38px;
      &.welfare-item-big {
        width: 66.66%;
      }
      .h4 {
        position: absolute;
        top: 47px;
        left: 38px;
        color: inherit;
        z-index: 99;
      }
      &:hover {
        .modal {
          height: 100%;
        }
      }
      .modal {
        position: absolute;
        width: 100%;
        height: 0;
        background: #ef8a0f;
        overflow: hidden;
        transition: ease all 0.3s;
        bottom: 0;
        left: 0;
        padding: 0 38px;
        p {
          font-size: 14px;
          margin: 123px 0 0;
        }
      }
    }
  }

  .j-swiper {
    position: relative;
    .swiper {
      // position: relative;
      overflow: hidden;
      margin-top: 73px;
      .swiper-slide {
        .el-image {
          width: 57.33%;
          height: 100%;
        }
        .info {
          flex: 1;
          color: #333;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          padding: 55px;
          .title {
            font-size: 18px;
            line-height: 30px;
            margin: 0;
          }
          .avator-box {
            .el-image {
              width: 82px;
              height: 82px;
              border-radius: 50%;
              margin-right: 12px;
            }
            .avator-info {
              color: #333;
              flex-direction: column;
              .subtitle {
                font-size: 18px;
                margin: 0 0 18px;
              }
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .custom-swiper-button {
      position: absolute;
      top: 50%;
      // margin-top: -calc(100% - 113px);
      width: 64px;
      height: 64px;
      background: rgba(0, 0, 0, 0.5);
      text-align: center;
      line-height: 64px;
      font-size: 24px;
      color: #fff;
      cursor: pointer;
      &.custom-swiper-button-prev {
        left: -88px;
      }
      &.custom-swiper-button-next {
        right: -88px;
      }
    }
  }

  .j-story {
    margin-bottom: 80px;
    .h4 {
      text-align: left;
      margin-bottom: 60px;
    }
    .story-box {
      width: 100%;
      justify-content: space-between;
      .story-item {
        position: relative;
        width: 46.66%;
        height: 480px;
        background: #fff;
        box-shadow: 0 8px 13px 0 rgb(0 0 0 / 6%);
        .story-bg {
          width: 100%;
          height: 280px;
        }
        .story-text-wrapper {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 91.045%;
          height: 256px;
          background: #fff;
          transition: all 0.3s;
          color: #333;
          flex-direction: column;
          text-align: left;
          padding: 32px 32px 0;
          margin: 0 auto;
          span.name {
            font-size: 16px;
            margin-bottom: 19px;
          }
          p {
            font-size: 24px;
            margin: 0 0 46px;
          }
          p.more {
            font-size: 16px;
            color: #80a9ec;
            margin: 0;
          }
          .el-divider {
            background: #80a9ec;
            // margin: 34px 0 0;
          }
        }
      }
    }
  }
}

/deep/ .footer {
  margin-top: 0;
}
</style>
